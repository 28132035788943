import {inject, Injectable} from '@angular/core';
import {AngularFirestore, QueryFn} from '@angular/fire/compat/firestore';
import {
  animationFrameScheduler,
  combineLatest,
  EMPTY,
  from,
  Observable,
  of,
  ReplaySubject,
  Subject,
  throwError,
} from 'rxjs';
import {v4 as uuidv4} from 'uuid';
import {ActiveStoreModel, SessionModel} from '../interfaces/store-models';

import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {Timestamp} from '@angular/fire/firestore';
import {ActivationEnd, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {asapScheduler} from 'rxjs';
import {
  catchError,
  filter,
  map,
  retry,
  share,
  shareReplay,
  subscribeOn,
  switchMap,
  take,
  takeWhile,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {UsersService} from 'src/app/services/users.service';
import type {DbCurrencyModel} from '../../../../shared/db-models/payments';
import {
  DbSessionDataStatisticModel,
  DbSessionModel,
  DbSessionPollModel,
  DbSessionProductModel,
  DbSessionProductVariantModel,
  DbSessionPromoVideoModel,
  DiscountTypes,
  PollStatus,
} from '../../../../shared/db-models/session';
import {
  DbStoreManagerModel,
  DbStoreModel,
  DbStoreShippingMethodModel,
  DbStoreUserInviteModel,
} from '../../../../shared/db-models/store';
import {
  IECommercePlatformIntegration,
  integrationDocsPath,
} from '../../../../shared/ecommerce-platform-integration/ecommerce-platform-integration';
import {CacheAbleShare} from '../helpers/cache.decoretor';
import {updateTheme} from '../helpers/customization-ui-helpers';
import {FileHelpers} from '../helpers/file-helpers';
import {ConnectedUserModel} from '../interfaces/users-models';
import {LanguageService} from '../language.service';
import {LogService} from '../logger/logger.service';
import {AnalyticsService} from './analytics.service';
import {AppService} from './app.service';
import {DynamicLinksService} from './dynamic-links.service';
import {NavigateService} from './navigate.service';
import {StateHolderService} from './state-holder.service';
import {StorageService} from './storage.service';
import {Collections, Docs} from '../../../../shared/db-models/db-structure';

@Injectable({
  providedIn: 'root',
})
export class StoresService {
  private firestore = inject(AngularFirestore);
  private fns = inject(AngularFireFunctions);
  private storage = inject(StorageService);
  private dynamicLinksService = inject(DynamicLinksService);
  protected appService = inject(AppService);
  public languageService = inject(LanguageService);
  protected usersService = inject(UsersService);
  protected navigationService = inject(NavigateService);
  protected router = inject(Router);
  protected stateService = inject(StateHolderService);
  private analytics = inject(AnalyticsService);
  private logService = inject(LogService);
  private toastr: ToastrService = inject(ToastrService);

  private activeStore: ActiveStoreModel | null = null;
  public isPublishedLocal: boolean;
  public pictureInPictureLocal: boolean;
  public mirrorHostLocal: boolean;
  private storeUrl$ = new ReplaySubject<string | null | undefined>(1);
  public activeStoreDoc$ = this.storeUrl$.pipe(
    switchMap((url) => {
      if (url) return this.getStoreByUrl(url);
      return this.stateService.globalStoreUrl$.pipe(
        filter((url) => {
          return !!url;
        }),
        switchMap((url) => {
          return this.getStoreByUrl(url!);
        })
      );
    }),
    shareReplay(1),
    catchError(() => {
      //Return an empty Observable which gets collapsed in the output
      return EMPTY;
    })
  );
  public activeStore$ = combineLatest({
    store: this.activeStoreDoc$,
    user: this.usersService.connectedUser,
  }).pipe(
    catchError(() => {
      //Return an empty Observable which gets collapsed in the output
      return EMPTY;
    }),
    map((data) => {
      if (!data.store) {
        this.activeStore = null;
        return null;
      }
      // Init the store model
      const storeData: DbStoreModel = data.store;
      const store = new ActiveStoreModel();
      store.id = storeData.id;
      store.name = storeData.name;
      store.logoUrl = storeData.logoUrl;
      store.productsLogoUrl = storeData.productsLogoUrl;
      store.currency = storeData.currency;
      store.url = storeData.url;
      store.fullData = storeData;
      store.theme = storeData.theme;
      store.sessionActionsRequireLoginDefault = storeData.sessionActionsRequireLoginDefault;
      if (
        storeData.defaultLanguage &&
        !this.languageService.getSavedLanguageCurrentStore(store.id)
      ) {
        this.languageService.changeLanguage(storeData.defaultLanguage);
      }

      this.updateStorePermissions(store, data.user);

      // Validate the store is published
      if (!storeData.isPublished && !store.isManager) throw new Error('Store not available');

      this.activeStore = store;
      this.isPublishedLocal = store.fullData.isPublished;

      this.pictureInPictureLocal = store.fullData.pictureInPicture ?? false; // by default should  be true if some stores do not have this parameter set
      this.mirrorHostLocal = store.fullData.mirrorHost ?? true; // by default should  be true if some stores do not have this parameter set
      return store;
    }),
    shareReplay({refCount: true, bufferSize: 1})
  );

  public currentStoreCurrency$ = combineLatest({
    currencies: this.getAllCurrencies(),
    storeDoc: this.activeStoreDoc$,
  }).pipe(
    map(({currencies, storeDoc}) =>
      currencies.find((currency) => currency.id === storeDoc.currencyId)
    ),
    share(),
    shareReplay({refCount: true, bufferSize: 1})
  );

  constructor() {
    combineLatest({
      store: this.activeStore$,
      themeIsActive: this.stateService.themeActive$,
    }).subscribe((data) => {
      data.themeIsActive ? updateTheme(data.store?.theme) : updateTheme(undefined);
    });

    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof ActivationEnd &&
            (!!event.snapshot.params.storeUrl || !!this.stateService.globalStoreUrl$.value)
        ),
        withLatestFrom(this.stateService.globalStoreUrl$)
      )
      .subscribe(([event, globalStoreUrl]) => {
        if (event instanceof ActivationEnd) {
          const storeUrl = event.snapshot.params.storeUrl;

          this.loadActiveStore(storeUrl ?? globalStoreUrl)
            .pipe(take(1))
            .subscribe({
              error: () => {
                // Store doesn't exist ot server error
                from(this.router.navigate(['/'])).subscribe();
              },
            });
        }
      });

    this.stateService.globalStoreUrl$
      .pipe(withLatestFrom(this.storeUrl$))
      .subscribe(([globalUrl, storeUrl]) => {
        if (storeUrl) return this.loadActiveStore(storeUrl).pipe(take(1)).subscribe();
        if (!globalUrl) return;
        return this.loadActiveStore(globalUrl).pipe(take(1)).subscribe();
      });

    this.storeUrl$.next(null);
  }

  public getActiveStoreSync(): ActiveStoreModel | null {
    return this.activeStore;
  }

  public async checkIfStoreHasIntegration(): Promise<boolean> {
    if (!this.activeStore) return false;
    const querySnapshot = await this.firestore
      .collection('ecommercePlatformIntegrations')
      .ref.where('terrificStoreIds', 'array-contains', this.activeStore?.id)
      .limit(1)
      .get();
    return !querySnapshot.empty;
  }

  public isRemindMeOn(): boolean {
    const reminderOptions = this.activeStore?.fullData.reminderOptions;
    return !!reminderOptions?.phone || !!reminderOptions?.email;
  }

  public loadActiveStore(storeUrl: string): Observable<ActiveStoreModel | null> {
    this.storeUrl$.next(storeUrl);
    return this.activeStore$;
  }

  private updateStorePermissions(store: ActiveStoreModel, user: ConnectedUserModel | null) {
    if (user) {
      if (user.isAdmin) {
        store.isManager = true;
      } else {
        store.isManager = !!(
          user.stores &&
          user.stores[store.id] &&
          user.stores[store.id].role === 'admin'
        );
      }
    } else {
      store.isManager = false;
    }
  }

  public getAllPublishedStores(): Observable<DbStoreModel[]> {
    return this.firestore
      .collection<DbStoreModel>(`stores`, (ref) => ref.where('isPublished', '==', true))
      .valueChanges()
      .pipe(retry({count: 3, delay: 300, resetOnSuccess: true}));
  }

  private getStoreByUrl(storeUrl: string) {
    return this.firestore
      .collection<DbStoreModel>('stores', (ref) => ref.where('url', '==', storeUrl).limit(1))
      .valueChanges({idField: 'id'})
      .pipe(
        map((stores) => {
          if (!stores.length) throw new Error('store with url ' + storeUrl + ' does not exist');
          return stores[0];
        }),
        tap((store) => {
          store.pixelId &&
            store.pixelId.split(',').forEach((id) => this.analytics.addPixelId(String(id).trim()));
        })
      );
  }
  public getStoreById(storeId: string): Observable<
    DbStoreModel & {
      id: string;
    }
  > {
    if (!storeId) return EMPTY;
    if (this.activeStore?.id === storeId)
      return this.activeStoreDoc$.pipe(takeWhile((x) => !!x && x.id === storeId));
    return this.firestore
      .collection<DbStoreModel>('stores', (ref) => ref.where('id', '==', storeId).limit(1))
      .valueChanges()
      .pipe(
        map((stores) => {
          if (!stores.length) throw new Error(`store with id ${storeId} does not exist`);
          return stores[0];
        }),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  public getStoreShippingMethods(storeId: string): Observable<DbStoreShippingMethodModel[]> {
    if (!storeId) return of([]);
    return this.firestore
      .collection<DbStoreShippingMethodModel>(`stores/${storeId}/shippingMethods`, (ref) =>
        ref.limit(1000)
      )
      .get({source: 'server'})
      .pipe(
        retry({count: 3, delay: 300, resetOnSuccess: true}),
        map((x) => x.docs.map((x) => ({...x.data(), id: x.id}))),
        switchMap((methods) => {
          // this logic handles duplicate shipping methods
          // and empty shipping methods
          // https://terrific-force.monday.com/boards/1939736845/pulses/5027356986
          let methodDeleted = false;
          const map = new Map<string, number | undefined>();
          methods.forEach((method) => {
            if (method.name && !map.has(method.name)) {
              map.set(method.name, method.price);
              return;
            }
            methodDeleted = true;

            // deleteAfterCurrentTask
            this.deleteShippingMethod(method)
              .pipe(subscribeOn(asapScheduler))
              .subscribe({error: (e) => this.logService.error(`delete failed`, e)});
          });

          if (methodDeleted) {
            // return a new observable to get the updated shipping methods
            return this.getStoreShippingMethods(storeId).pipe(subscribeOn(animationFrameScheduler));
          }
          return of(methods);
        })
      );
  }

  // region Store Managers

  /**
   *
   * @param storeId
   */
  public getStoreManagers(storeId: string): Observable<DbStoreManagerModel[]> {
    return this.firestore
      .collection<DbStoreManagerModel>(`stores/${storeId}/managers`)
      .valueChanges()
      .pipe(retry({count: 3, delay: 300, resetOnSuccess: true}));
  }

  /**
   *
   * @param storeId
   */
  public getStoreUserInvites(storeId: string): Observable<DbStoreUserInviteModel[]> {
    return this.firestore
      .collection<DbStoreUserInviteModel>(`storeUserInvites`, (ref) =>
        ref.where('storeId', '==', storeId).where('active', '==', true)
      )
      .valueChanges()
      .pipe(retry({count: 3, delay: 300, resetOnSuccess: true}));
  }

  /**
   *
   * @param storeId
   * @param email
   */
  public inviteUserAsStoreAdmin(storeId: string, email: string): Observable<'OK'> {
    return this.fns
      .httpsCallable('inviteUserAsStoreAdmin')({storeId, email})
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  /**
   *
   * @param storeId
   * @param email
   */
  public removeStoreAdmin(storeId: string, email: string): Observable<string> {
    return this.fns
      .httpsCallable('removeStoreAdmin')({storeId, email})
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  // endregion

  // region Store Settings

  /**
   * Creates or updates a shipping method and returns it's id
   *
   * @param shippingMethod The shipping method object
   */
  public updateShippingMethod(
    shippingMethod: DbStoreShippingMethodModel
  ): Observable<string | undefined> {
    const subject = new Subject<string | undefined>();

    shippingMethod = Object.assign({}, shippingMethod);

    if (shippingMethod.id) {
      this.firestore
        .doc(`stores/${shippingMethod.storeId}/shippingMethods/${shippingMethod.id}`)
        .update(shippingMethod)
        .then(() => {
          subject.next(shippingMethod.id);
        })
        .catch((err) => {
          subject.error(err);
        })
        .finally(() => {
          subject.complete();
        });
    } else {
      this.firestore
        .collection(`stores/${shippingMethod.storeId}/shippingMethods`)
        .add(shippingMethod)
        .then((newDoc) => {
          subject.next(newDoc.id);
        })
        .catch((err) => {
          subject.error(err);
        })
        .finally(() => {
          subject.complete();
        });
    }

    return subject.asObservable();
  }

  deleteShippingMethod(x: DbStoreShippingMethodModel) {
    if (!x.id) return EMPTY;
    const doc = this.firestore.doc(`stores/${x.storeId}/shippingMethods/${x.id}`);
    return doc.get().pipe(switchMap((x) => (x.exists ? from(doc.delete()) : EMPTY)));
  }

  /**
   * Updates a stores settings
   *
   * @param storeId The store id
   * @param store The store data
   */
  public updateStoreSettings(storeId: string, store: DbStoreModel): Observable<any> {
    const copy: any = Object.assign({}, store);

    if (copy.shippingMethods) delete copy.shippingMethods;

    return from(this.firestore.doc(`stores/${storeId}`).update(copy));
  }

  /**
   * Upload a store sizes file
   *
   * @param storeId The store id
   * @param file The sizes file
   */
  public uploadSizesFile(storeId: string, file: File): Observable<string> {
    return this.storage.uploadFileToStorage(
      `stores/${storeId}/sizes/${uuidv4()}.${FileHelpers.getFileExtension(file)}`,
      file,
      '.jpg,.png,.jpeg',
      5
    );
  }

  public uploadVideoFileToStorage(
    storeId: string,
    file: File,
    videoPath = `stores/${storeId}/promotionVideos/${uuidv4()}.${FileHelpers.getFileExtension(
      file
    )}`
  ): Observable<string | undefined> {
    return this.storage.uploadFileToStorage(videoPath, file, '.mp4', 30);
  }

  public uploadThumbnailToStorage(
    file: File,
    thumbnailPath: string
  ): Observable<string | undefined> {
    return this.storage.uploadFileToStorage(thumbnailPath, file, '.jpg', 2);
  }

  /**
   * Upload a store logo file
   *
   * @param storeId The store id
   * @param file The logo file
   */
  public uploadMainLogoFile(storeId: string, file: File): Observable<string> {
    return this.storage.uploadFileToStorage(
      `stores/${storeId}/logos/${uuidv4()}.${FileHelpers.getFileExtension(file)}`,
      file,
      '.svg,.png',
      0.25
    );
  }

  /**
   * Upload a store products logo file
   *
   * @param storeId The store id
   * @param file The products logo file
   */
  public uploadProductLogoFile(storeId: string, file: File): Observable<string> {
    return this.storage.uploadFileToStorage(
      `stores/${storeId}/logos/${uuidv4()}.${FileHelpers.getFileExtension(file)}`,
      file,
      '.svg',
      0.5
    );
  }

  /**
   * Upload a store emails logo file
   *
   * @param storeId The store id
   * @param file The emails logo file
   */
  public uploadEmailsLogoFile(storeId: string, file: File): Observable<string> {
    return this.storage.uploadFileToStorage(
      `stores/${storeId}/logos/${uuidv4()}.${FileHelpers.getFileExtension(file)}`,
      file,
      '.png',
      0.5
    );
  }

  /**
   * Upload a store banner file
   *
   * @param storeId The store id
   * @param file The banner file
   */
  public uploadMainBannerFile(storeId: string, file: File): Observable<string> {
    const path = `stores/${storeId}/banners/${uuidv4()}.${FileHelpers.getFileExtension(file)}`;
    return this.storage.uploadFileToStorage(path, file, '.jpg,.png,.jpeg,.webp', 2);
  }

  // endregion

  // region Currencies

  public getAllCurrencies(): Observable<DbCurrencyModel[]> {
    return this.firestore.collection<DbCurrencyModel>(`currencies`).valueChanges({idField: 'id'});
  }

  public getStoreCurrency(currencyId: string) {
    return this.firestore
      .doc<DbCurrencyModel>(`currencies/${currencyId}`)
      .valueChanges()
      .pipe(retry({count: 3, delay: 300, resetOnSuccess: true}));
  }

  // endregion

  /**
   * Upload a product image
   *
   * @param storeId The store id
   * @param productId The product id
   * @param file The image file
   */
  public uploadProductImage(storeId: string, productId: string, file: File): Observable<string> {
    const filePath = `stores/${storeId}/products/${productId}/${uuidv4()}.${FileHelpers.getFileExtension(
      file
    )}`;
    return this.storage.uploadFileToStorage(filePath, file, '.jpg,.png,.jpeg,.webp', 2);
  }

  // endregion

  // region Sessions
  @CacheAbleShare()
  public getStoreSessions(storeId: string) {
    return this.firestore
      .collection<SessionModel>('sessions', (ref) =>
        ref.where('storeId', '==', storeId).orderBy('startTime', 'desc')
      )
      .valueChanges()
      .pipe(
        map((sessions) =>
          sessions.map((session) => {
            return {
              ...session,
              startTime: new Timestamp(session.startTime.seconds, session.startTime.nanoseconds),
            };
          })
        ),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  /**
   * Get more 12 sessions
   *
   * @param storeId
   * @param lastVisibleSessions
   */
  @CacheAbleShare()
  public getStoreActiveSessions(storeId: string) {
    const queryFn: QueryFn = (ref) =>
      ref
        .where('storeId', '==', storeId)
        .where('isActive', '==', true)
        .orderBy('hasEnded', 'asc')
        .orderBy('startTime', 'asc');
    return this.firestore
      .collection<SessionModel>('sessions', queryFn)
      .valueChanges({idField: 'id'})
      .pipe(
        map((sessions) =>
          sessions.filter(Boolean).map((session) => {
            return {
              ...session,
              startTime: new Timestamp(session.startTime.seconds, session.startTime.nanoseconds),
            };
          })
        )
      );
  }
  /**
   * Get all the sessions if is active or not
   */
  @CacheAbleShare()
  public getAllSessions() {
    const queryFn: QueryFn = (ref) =>
      ref.where('isActive', '==', true).orderBy('hasEnded', 'asc').orderBy('startTime', 'asc');
    return this.firestore
      .collection<DbSessionModel>('sessions', queryFn)
      .valueChanges({idField: 'id'})
      .pipe(
        map((sessions) =>
          sessions.filter(Boolean).map((session) => {
            return {
              ...session,
              startTime: new Timestamp(session.startTime.seconds, session.startTime.nanoseconds),
            } as DbSessionModel;
          })
        )
      );
  }

  public getSessionProducts(storeId: string, sessionId: string) {
    return this.firestore
      .collection<DbSessionProductModel>(`sessions/${sessionId}/sessionProducts`, (ref) =>
        ref.where('storeId', '==', storeId)
      )
      .valueChanges()
      .pipe(retry({count: 3, delay: 300, resetOnSuccess: true}));
  }

  public getSessionProductVariants(storeId: string, sessionId: string) {
    return this.firestore
      .collectionGroup<DbSessionProductVariantModel>(`sessionProductVariants`, (query) =>
        query.where('storeId', '==', storeId).where('sessionId', '==', sessionId)
      )
      .valueChanges()
      .pipe(retry({count: 3, delay: 300, resetOnSuccess: true}));
  }

  /**
   * Creates or updates a session and returns it's id
   *
   * @param session The session object
   */
  public updateSession(session: DbSessionModel): Observable<string> {
    const subject = new Subject<string>();

    session = Object.assign({}, session);
    session.viewersPercentageDiscounts = session.viewersPercentageDiscounts.map((x) =>
      Object.assign({}, x)
    );
    session.viewersItemDiscounts = session.viewersItemDiscounts.map((x) => Object.assign({}, x));
    session.discountType =
      session.viewersPercentageDiscounts.length || session.viewersItemDiscounts.length
        ? session.discountType
        : DiscountTypes.GeneralDiscount;
    session.hostImage = session.hostImage ?? 'assets/images/avatar-placeholder.svg';

    if (session.id) {
      this.firestore
        .doc(`sessions/${session.id}`)
        .set(session)
        .then(() => {
          this.logService.debug('session %s saved successfully', session.id, session);
          subject.next(session.id);
        })
        .catch((err) => {
          subject.error(err);
        })
        .finally(() => {
          subject.complete();
        });
    } else {
      const doc = this.firestore.collection(`sessions`).doc();
      doc
        .set({...session, id: doc.ref.id})
        .then(() => {
          this.logService.debug('session %s saved successfully', session.id, session);
          subject.next(doc.ref.id);
        })
        .catch((err) => {
          subject.error(err);
        })
        .finally(() => subject.complete());
    }

    return subject.asObservable();
  }

  /**
   * Creates or updates a session product
   *
   * @param sessionProduct The session product object
   */
  public updateSessionProduct(sessionProduct: DbSessionProductModel): Observable<any> {
    const path = `sessions/${sessionProduct.sessionId}/sessionProducts/${sessionProduct.productId}`;
    return from(this.firestore.doc(path).set(Object.assign({}, sessionProduct)));
  }

  /**
   * Deletes a session product
   *
   * @param sessionProduct The session product object
   */
  public deleteSessionProduct(sessionProduct: DbSessionProductModel): Observable<any> {
    const path = `sessions/${sessionProduct.sessionId}/sessionProducts/${sessionProduct.productId}`;
    return from(this.firestore.doc(path).delete());
  }

  /**
   * Creates a session product variant
   *
   * @param sessionProductVariant The session product variant object
   */
  public setSessionProductVariant(
    sessionProductVariant: DbSessionProductVariantModel
  ): Observable<any> {
    const path = `sessions/${sessionProductVariant.sessionId}/sessionProducts/${sessionProductVariant.productId}/sessionProductVariants/${sessionProductVariant.variantId}`;
    return from(this.firestore.doc(path).set(Object.assign({}, sessionProductVariant)));
  }

  //creates a promoVideo doc in the promoVideos collection within a session. if the collection doesn't exist - it creates one.
  createSessionPromoVideo(sessionId: string, incomingPromoVideoModel: DbSessionPromoVideoModel) {
    const newId = this.firestore.createId();
    return from(
      this.firestore
        .collection<DbSessionPromoVideoModel>(`sessions/${sessionId}/sessionPromoVideos`)
        .doc(newId)
        .set({
          id: newId,
          isActive: true,
          length: incomingPromoVideoModel.length,
          lengthAsString: (incomingPromoVideoModel.length * 1000).toString(),
          link: incomingPromoVideoModel.link,
          name: incomingPromoVideoModel.name,
          thumbnail: incomingPromoVideoModel.thumbnail,
          productId: incomingPromoVideoModel.productId,
          variantId: incomingPromoVideoModel.variantId,
        })
    );
  }

  //delele promo video from firebase by getting the session id and the video id.
  public deletePromoVideo(sessionId: string, video: DbSessionPromoVideoModel): Observable<any> {
    const subject = new Subject<string>();
    this.firestore
      .doc(`sessions/${sessionId}/sessionPromoVideos/${video.id}`)
      .delete()
      .then(() => {
        subject.next(video.name);
      })
      .catch(() => {
        subject.complete();
      })
      .finally(() => {
        subject.complete();
      });
    return subject.asObservable();
  }

  public deletePromoVideoFile(storeId: string, sessionId: string, video: DbSessionPromoVideoModel) {
    this.deletePromoVideo(sessionId, video);
    const videoId = video.link.substring(
      video.link.lastIndexOf('promotionVideos%2F') + 18,
      video.link.lastIndexOf('.mp4') + 4
    );
    const videoPath = `stores/${storeId}/promotionVideos/${videoId}`;
    const thumbnailPath = videoPath.replace('.mp4', '.jpg');

    // Delete both the video and the thumbnail
    return this.storage.deleteFiles([videoPath, thumbnailPath]);
  }

  //returns a snapshot of the promo video collection as an array of DbsessionPromoModel objects
  public getPromoVideos(sessionId: string) {
    return this.firestore
      .collection<DbSessionPromoVideoModel>(`sessions/${sessionId}/sessionPromoVideos`, (ref) =>
        ref.where('isActive', '==', true)
      )
      .valueChanges()
      .pipe(retry({count: 3, delay: 300, resetOnSuccess: true}));
  }

  /**
   * Delete a session product variant
   *
   * @param sessionProductVariant The session product variant object
   */
  public deleteSessionProductVariant(
    sessionProductVariant: DbSessionProductVariantModel
  ): Observable<any> {
    const path = `sessions/${sessionProductVariant.sessionId}/sessionProducts/${sessionProductVariant.productId}/sessionProductVariants/${sessionProductVariant.variantId}`;
    return from(this.firestore.doc(path).delete());
  }

  /**
   * Creates or updates a session poll and returns it's id
   *
   * @param sessionPoll The session poll object
   */
  public updateSessionPoll(sessionPoll: DbSessionPollModel): Observable<string | undefined> {
    const subject = new Subject<string | undefined>();

    sessionPoll = Object.assign({}, sessionPoll);
    if (sessionPoll.id) {
      if (sessionPoll.status === PollStatus.Inactive) {
        // Do not allow edit active polls
        this.firestore
          .doc(`sessions/${sessionPoll.sessionId}/sessionPolls/${sessionPoll.id}`)
          .update(sessionPoll)
          .then(() => {
            subject.next(sessionPoll.id);
          })
          .catch((err) => {
            subject.error(err);
          })
          .finally(() => {
            subject.complete();
          });
      }
    } else {
      const sessionPollId = this.firestore.createId();
      sessionPoll.id = sessionPollId;
      this.firestore
        .doc(`sessions/${sessionPoll.sessionId}/sessionPolls/${sessionPollId}`)
        .set(sessionPoll)
        .then(() => {
          subject.next(sessionPoll.id);
        })
        .catch((err) => {
          subject.error(err);
        })
        .finally(() => {
          subject.complete();
        });
    }

    return subject.asObservable();
  }

  /**
   * Delete a session poll
   *
   * @param sessionPoll The session poll
   */
  public deleteSessionPoll(sessionPoll: DbSessionPollModel): Observable<any> {
    const path = `sessions/${sessionPoll.sessionId}/sessionPolls/${sessionPoll.id}`;
    return from(this.firestore.doc(path).update({status: PollStatus.Deleted}));
  }

  /**
   * Upload a session cover image
   *
   * @param storeId The store id
   * @param sessionId The session id
   * @param file The cover image
   */
  public uploadSessionCoverFile(
    storeId: string,
    sessionId: string,
    file: File
  ): Observable<string> {
    const path = `stores/${storeId}/sessions/${sessionId}/${uuidv4()}.${FileHelpers.getFileExtension(
      file
    )}`;
    return this.storage.uploadFileToStorage(path, file, '.jpg,.png,.jpeg,.webp', 2);
  }

  /**
   * Upload a session host image
   *
   * @param storeId The store id
   * @param sessionId The session id
   * @param file The host image
   */
  public uploadSessionHostImageFile(
    storeId: string,
    sessionId: string,
    file: File
  ): Observable<string> {
    const path = `stores/${storeId}/sessions/${sessionId}/${uuidv4()}.${FileHelpers.getFileExtension(
      file
    )}`;
    return this.storage.uploadFileToStorage(path, file, '.jpg,.png,.jpeg,.webp', 2);
  }

  public inviteUserAsSessionHost(sessionId: string, userEmail: string): Observable<'OK'> {
    if (!sessionId || !userEmail?.includes('@')) {
      return throwError(() => new Error('Invalid data provided to inviteUserAsSessionHost'));
    }
    return this.fns
      .httpsCallable('inviteUserAsSessionHost')({
        sessionId,
        userEmail,
      })
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  public exportSessionOrdersCsv(sessionId: string): Observable<string> {
    return this.fns
      .httpsCallable('exportSessionOrdersCsv')({
        sessionId,
      })
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  public exportSessionRemindersCsv(sessionId: string): Observable<string> {
    return this.fns
      .httpsCallable<any, string>('exportSessionRemindersCsv')({sessionId})
      .pipe(retry({count: 3, delay: 300, resetOnSuccess: true}));
  }

  public exportSessionChatMessagesCsv(sessionId: string): Observable<string> {
    return this.fns
      .httpsCallable('exportSessionChatMessagesV2')({
        sessionId,
      })
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  public deleteSession(sessionId: string): Observable<string> {
    return this.fns
      .httpsCallable('deleteSession')({
        sessionId,
      })
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  public toggleSessionActiveState(sessionId: string, isActive: boolean): Observable<string> {
    return this.fns
      .httpsCallable('toggleSessionActiveState')({
        sessionId,
        isActive,
      })
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  // endregion

  // region App Keys

  public watchEcommercePlatformIntegrations(storeId: string) {
    if (!storeId) {
      return of([]);
    }
    return this.firestore
      .collection<IECommercePlatformIntegration>(integrationDocsPath(), (ref) =>
        ref.where('terrificStoreIds', 'array-contains', storeId)
      )
      .valueChanges()
      .pipe(retry({count: 3, delay: 300, resetOnSuccess: true}));
  }

  public getSessionsStatistic(): Observable<DbSessionDataStatisticModel[]> {
    if (!this.activeStore) {
      this.logService.debug('no active store found');
      return of([]);
    }
    const storeId = this.activeStore.id;
    this.logService.debug(`try get session statistics for store ${storeId}`);

    return this.firestore
      .collectionGroup<DbSessionDataStatisticModel>(Collections.SESSION_DATA, (ref) =>
        ref.where('id', '==', Docs.SESSION_STATISTIC_DOC_ID).where('storeId', '==', storeId)
      )
      .valueChanges()
      .pipe(
        retry({count: 3, delay: 300, resetOnSuccess: true}),
        catchError((e) => this.languageService.translateError(e))
      );
  }

  public triggerWowFlow(
    name: string,
    url: string,
    internalUrl: string
  ): Observable<{sessionData: DbSessionModel}> {
    return this.fns
      .httpsCallable('triggerWowFlow')({
        storeExternalUrl: url,
        storeName: name,
        storeInternalUrl: internalUrl,
      })
      .pipe(
        catchError((error) => {
          this.logService.error('store service ~ triggerWowFlow ~ error', error);
          return throwError(() => {
            return {
              code: error?.code || '',
              message: error?.message || '',
              details: error?.details || '',
            };
          });
        })
      );
  }

  public getFileFromAssets(imagePath: string, fileName: string, type: string): Observable<File> {
    return from(
      fetch(imagePath)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.blob();
        })
        .then((blob) => {
          return new File([blob], fileName, {type});
        })
    );
  }

  public syncIntegrationData(): Observable<void> {
    const storeId = this.activeStore?.id;
    return this.fns.httpsCallable('triggerIntegrationDataSync')({storeId});
  }
}
