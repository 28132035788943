<div class="container-xl">
  <div
    class="desktop-logo-container"
    [style.visibility]="state === AuthModalState.AskForDetails && showFields ? 'hidden' : 'visible'"
  >
    <div class="brand-logo-container">
      <img [src]="logoUrl ? logoUrl : 'assets/images/logo-terrific.svg'" alt="" class="logo" />
    </div>
    <img
      *ngIf="logoUrl"
      alt="Powered by Terrific"
      class="powered-by-terrific"
      src="assets/images/powered-by-terrific.svg"
    />
  </div>

  <!-- Close modal button -->
  <button class="btn-close-modal" type="button" (click)="dismissModal()">
    <img src="assets/images/icons/close-modal-icon.svg" alt="Close" />
  </button>

  <div class="content">
    <!-- if Login/Register -->
    <ng-container *ngIf="state === AuthModalState.Login || state === AuthModalState.Register">
      <!-- Back button -->
      <button class="btn-base back-button main-back-button" type="button" (click)="modal.dismiss()">
        <i class="icon iconly-Arrow-Left icli"></i> {{ 'LOGIN_MODAL.BUTTON_BACK' | translate }}
      </button>
      <div class="wide-wrapper">
        <p class="title text-center">
          <span class="font-weight-normal">{{ 'LOGIN_MODAL.HEY_YOU' | translate }}</span
          ><br />
          {{ 'LOGIN_MODAL.JOIN_AND_GET_READY' | translate }}<br />
          {{ 'LOGIN_MODAL.SHOP_LIVE' | translate }}<span class="pink">.</span>
        </p>
      </div>
      <div class="narrow-wrapper">
        <ul #nav="ngbNav" [(activeId)]="activeTab" ngbNav>
          <!-- Login tab -->
          <li [ngbNavItem]="1">
            <a ngbNavLink>{{ 'LOGIN_MODAL.LOG_IN' | translate }}</a>
            <ng-template ngbNavContent>
              <div class="tab-inner">
                <button
                  class="btn-login"
                  type="button"
                  (click)="signInWithProvider('Google')"
                  [disabled]="isSendingToServer"
                >
                  <img alt="Google" class="icon google" src="assets/images/google-g.svg" />
                  <div class="seperator"></div>
                  {{ 'LOGIN_MODAL.LOG_IN_GOOGLE' | translate }}
                </button>
                <button
                  *ngIf="false"
                  class="btn-login"
                  type="button"
                  (click)="signInWithProvider('Facebook')"
                  [disabled]="isSendingToServer"
                >
                  <img alt="Facebook" class="icon" src="assets/images/facebook-f.svg" />
                  <div class="seperator"></div>
                  {{ 'LOGIN_MODAL.LOG_IN_FACEBOOK' | translate }}
                </button>
                <button
                  class="btn-login"
                  type="button"
                  (click)="signInWithSms()"
                  [disabled]="isSendingToServer"
                >
                  <img alt="SMS" class="icon sms" src="assets/images/login-sms-icon.svg" />
                  <div class="seperator"></div>
                  {{ 'LOGIN_MODAL.LOG_IN_SMS' | translate }}
                </button>
                <div class="text-seperator">
                  <div class="seperator"></div>
                  <div class="text">{{ 'LOGIN_MODAL.LOG_IN_EMAIL' | translate }}</div>
                  <div class="seperator"></div>
                </div>
                <form (ngSubmit)="signInWithEmail()" [formGroup]="signInForm">
                  <div class="form-input-wrapper">
                    <div class="form-group">
                      <label class="sr-only" for="email">{{
                        'LOGIN_MODAL.EMAIL_ADDRESS' | translate
                      }}</label>
                      <input
                        class="form-control"
                        id="email"
                        name="email"
                        [placeholder]="'LOGIN_MODAL.EMAIL' | translate"
                        type="email"
                        formControlName="email"
                        required
                        email
                        (click)="removeErrorMessageSignInForm('email')"
                      />
                      <div
                        class="invalid-feedback"
                        *ngIf="
                          showEmailInvalidMessage ||
                          (signInForm.controls.email.invalid && signInForm.controls.email.touched)
                        "
                      >
                        {{ 'ERROR.SIGN_IN_INVALID_EMAIL' | translate }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="sr-only" for="password">{{
                        'LOGIN_MODAL.PASSWORD' | translate
                      }}</label>
                      <input
                        class="form-control"
                        id="password"
                        name="password"
                        [placeholder]="'LOGIN_MODAL.PASSWORD' | translate"
                        type="password"
                        formControlName="password"
                        required
                        (click)="removeErrorMessageSignInForm('password')"
                      />
                      <div
                        class="invalid-feedback"
                        *ngIf="
                          signInForm.controls.password.invalid &&
                          signInForm.controls.password.touched
                        "
                      >
                        {{ 'ERROR.SIGN_IN_INVALID_PASSWORD' | translate }}
                      </div>
                      <a class="forgot-password-link" (click)="goToForgotPassword()">{{
                        'LOGIN_MODAL.SIGN_IN_FORGOT_PASSWORD' | translate
                      }}</a>
                    </div>
                    <app-button
                      [type]="'submit'"
                      [disabled]="isSendingToServer || isLoginButtonDisabled"
                      [rounded]="true"
                    >
                      {{ 'LOGIN_MODAL.LOG_IN' | translate }}
                    </app-button>
                    <p class="note">
                      {{ 'LOGIN_MODAL.BRFORE_SIGN_UP' | translate }}
                      <button class="link" type="button" (click)="changeModalTab()">
                        {{ 'LOGIN_MODAL.SIGN_UP' | translate }}
                      </button>
                    </p>
                  </div>
                </form>
              </div>
            </ng-template>
          </li>
          <!-- Registration tab -->
          <li [ngbNavItem]="2">
            <a ngbNavLink>{{ 'LOGIN_MODAL.SIGN_UP' | translate }}</a>
            <ng-template ngbNavContent>
              <div class="tab-inner">
                <button (click)="signupWithGoogle()" class="btn-login" type="button">
                  <img alt="Google" class="icon google" src="assets/images/google-g.svg" />
                  <div class="seperator"></div>
                  {{ 'LOGIN_MODAL.SIGN_UP_GOOGLE' | translate }}
                </button>
                <button
                  *ngIf="false"
                  (click)="signupWithFacebook()"
                  class="btn-login"
                  type="button"
                >
                  <img alt="Facebook" class="icon" src="assets/images/facebook-f.svg" />
                  <div class="seperator"></div>
                  {{ 'LOGIN_MODAL.SIGN_UP_FACEBOOK' | translate }}
                </button>
                <button (click)="signupWithSms()" class="btn-login" type="button">
                  <img alt="SMS" class="icon sms" src="assets/images/login-sms-icon.svg" />
                  <div class="seperator"></div>
                  {{ 'LOGIN_MODAL.SIGN_UP_SMS' | translate }}
                </button>
                <div class="text-seperator">
                  <div class="seperator"></div>
                  <div class="text">{{ 'LOGIN_MODAL.SIGN_UP_WITH_EMAIL' | translate }}</div>
                  <div class="seperator"></div>
                </div>
                <form (ngSubmit)="signUpWithEmailAndPassword()" [formGroup]="signUpForm">
                  <div class="form-input-wrapper">
                    <div class="form-group">
                      <label class="sr-only" for="full-name">{{
                        'LOGIN_MODAL.FULL_NAME' | translate
                      }}</label>
                      <input
                        class="form-control"
                        formControlName="fullName"
                        id="full-name"
                        [placeholder]="'LOGIN_MODAL.FULL_NAME' | translate"
                        (click)="removeErrorMessageSignUpForm('fullName')"
                        required
                        type="text"
                      />
                      <div
                        class="invalid-feedback"
                        *ngIf="
                          signUpForm.controls.fullName.invalid &&
                          signUpForm.controls.fullName.touched
                        "
                      >
                        {{ 'ERROR.INVALID_NAME' | translate }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="sr-only" for="email">{{
                        'LOGIN_MODAL.EMAIL_ADDRESS' | translate
                      }}</label>
                      <input
                        class="form-control"
                        formControlName="email"
                        id="email"
                        name="email"
                        [placeholder]="'LOGIN_MODAL.EMAIL' | translate"
                        (click)="removeErrorMessageSignUpForm('email')"
                        required
                        type="email"
                      />
                      <div
                        class="invalid-feedback"
                        *ngIf="
                          showEmailInvalidMessage ||
                          (signUpForm.controls.email.invalid && signUpForm.controls.email.touched)
                        "
                      >
                        {{ 'ERROR.SIGN_IN_INVALID_EMAIL' | translate }}
                      </div>
                      <div class="invalid-feedback" *ngIf="showEmailAlreadyInUseMessage">
                        {{ 'ERROR.EMAIL_ALREADY_USED' | translate }}
                        <u (click)="goToForgotPassword()">{{
                          'LOGIN_MODAL.SIGN_IN_FORGOT_PASSWORD' | translate
                        }}</u
                        >?
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="sr-only" for="password">{{
                        'LOGIN_MODAL.PASSWORD' | translate
                      }}</label>
                      <input
                        [type]="showPassword ? 'text' : 'password'"
                        class="form-control form-control-password"
                        (click)="removeErrorMessageSignUpForm('password')"
                        formControlName="password"
                        id="password"
                        name="password"
                        [placeholder]="'LOGIN_MODAL.PASSWORD' | translate"
                        required
                      />
                      <button
                        (click)="toggleShowPassword('password')"
                        class="password-visibility"
                        type="button"
                      >
                        <img
                          [src]="
                            showPassword
                              ? 'assets/images/icons/eye-icon.svg'
                              : 'assets/images/icons/closed-eye-icon.svg'
                          "
                          alt="Hide password"
                        />
                      </button>
                      <div
                        class="invalid-feedback"
                        *ngIf="
                          signUpForm.controls.password.invalid &&
                          signUpForm.controls.password.touched
                        "
                      >
                        {{ 'ERROR.PASSWORD_TOO_SHORT' | translate }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="sr-only" for="confirm-password">{{
                        'LOGIN_MODAL.CONFIRM_PASSWORD' | translate
                      }}</label>
                      <input
                        [type]="showConfirmPassword ? 'text' : 'password'"
                        class="form-control form-control-password"
                        (click)="removeErrorMessageSignUpForm('confirmPassword')"
                        formControlName="confirmPassword"
                        id="confirm-password"
                        name="confirm-password"
                        [placeholder]="'LOGIN_MODAL.CONFIRM_PASSWORD' | translate"
                        required
                      />
                      <button
                        (click)="toggleShowPassword('confirmPassword')"
                        class="password-visibility"
                        type="button"
                      >
                        <img
                          [src]="
                            showConfirmPassword
                              ? 'assets/images/icons/eye-icon.svg'
                              : 'assets/images/icons/closed-eye-icon.svg'
                          "
                          alt="Show password"
                        />
                      </button>
                      <div
                        class="invalid-feedback"
                        *ngIf="
                          signUpForm.controls.confirmPassword.invalid &&
                          signUpForm.controls.confirmPassword.touched
                        "
                      >
                        {{ 'ERROR.PASSWORD_DONT_MATCH' | translate }}
                      </div>
                    </div>
                    <app-button [type]="'submit'" [rounded]="true" [fullWidth]="true">
                      {{ 'LOGIN_MODAL.SIGN_UP' | translate }}
                    </app-button>
                    <p class="note">
                      {{ 'LOGIN_MODAL.ALREADY_SIGN_IN' | translate }}
                      <button class="link" type="button" (click)="changeModalTab()">
                        {{ 'LOGIN_MODAL.LOG_IN' | translate }}
                      </button>
                    </p>
                  </div>
                </form>
              </div>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
      </div>
    </ng-container>

    <!-- if Enter Phone form -->
    <ng-container *ngIf="state === AuthModalState.PhoneNumber">
      <!-- Back button -->
      <button class="btn-base back-button" type="button" (click)="backFromPhoneForm()">
        <i class="icon iconly-Arrow-Left icli"></i> {{ 'LOGIN_MODAL.BUTTON_BACK' | translate }}
      </button>
      <div class="wide-wrapper">
        <app-icon [name]="'Call'" [size]="'56px'" class="big-icon-circle"></app-icon>
        <ng-container *ngIf="signUpMethod === SignUpMethod.Sms">
          <p class="title text-center">
            <span class="font-weight-normal">{{ 'LOGIN_MODAL.HEY_YOU' | translate }}</span
            ><br />
            {{ 'LOGIN_MODAL.JOIN_AND_GET_READY' | translate }}<br />
            {{ 'LOGIN_MODAL.SHOP_LIVE' | translate }}<span class="pink">.</span>
          </p>
        </ng-container>
        <ng-container *ngIf="signUpMethod !== SignUpMethod.Sms">
          <p class="title">{{ 'LOGIN_MODAL.HELP_PROTECT_ACCOUNT' | translate }}</p>
        </ng-container>

        <p class="help-text" *ngIf="signUpMethod !== SignUpMethod.Sms">
          {{ 'LOGIN_MODAL.DONT_WORRY_JUST_SAFETY_MEASURES' | translate }}
        </p>
        <ng-container *ngIf="signUpMethod === SignUpMethod.Sms">
          <p class="help-text" *ngIf="returnState === AuthModalState.Login">
            {{ 'LOGIN_MODAL.LOG_IN_SMS_SUBTITLE' | translate }}
          </p>
          <p class="help-text" *ngIf="returnState === AuthModalState.Register">
            {{ 'LOGIN_MODAL.SIGN_UP_SMS_SUBTITLE' | translate }}
          </p>
        </ng-container>
        <!-- Phone input -->
        <form
          class="form-input-wrapper phone-input-wrapper"
          (ngSubmit)="sendPhoneToServer()"
          [formGroup]="phoneForm"
        >
          <div class="form-row form-row-phone">
            <div class="form-group col-6 col-xl-5">
              <ng-select
                class="custom-style phone-prefix"
                formControlName="phonePrefix"
                id="inputPhonePrefix"
                [(ngModel)]="selectedCountry"
                [searchFn]="customSearchFn"
                [items]="countryCodes$ | async"
                bindLabel="dialCode"
              >
                <ng-template ng-option-tmp ng-label-tmp let-item="item">
                  <i
                    class="flag flag-24"
                    [ngClass]="
                      item.isoCode ? 'flag-' + item.isoCode.toLocaleLowerCase() : 'flag-unknown'
                    "
                  ></i>
                  {{ item.dialCode }}
                  {{ item.name }}
                </ng-template>
              </ng-select>
            </div>
            <div class="form-group col-6 col-xl-7 mt-auto">
              <label class="sr-only" for="phone-number">{{
                'LOGIN_MODAL.PHONE_NUMBER' | translate
              }}</label>
              <input
                class="form-control"
                formControlName="phoneNumber"
                id="phone-number"
                required
                type="tel"
              />
              <div class="invalid-feedback" *ngIf="showPhoneInvalidMessage">
                {{ showPhoneInvalidMessage }}
              </div>
            </div>
          </div>
          <app-button
            *ngIf="!isSendingToServer"
            [disabled]="isSendingToServer"
            [type]="'submit'"
            [rounded]="true"
          >
            {{ 'LOGIN_MODAL.NEXT_BUTTON' | translate }}
          </app-button>
          <app-loader *ngIf="isSendingToServer"></app-loader>
        </form>
      </div>
    </ng-container>

    <!-- if Code verification form -->
    <ng-container *ngIf="state === AuthModalState.CodeVerification">
      <!-- Back button -->
      <button
        class="btn-base back-button"
        type="button"
        (click)="state = AuthModalState.PhoneNumber"
      >
        <i class="icon iconly-Arrow-Left icli"></i> {{ 'LOGIN_MODAL.BUTTON_BACK' | translate }}
      </button>
      <div class="wide-wrapper">
        <app-icon [name]="'Lock'" [size]="'56px'" class="big-icon-circle"></app-icon>
        <p class="title">{{ 'LOGIN_MODAL.VERIFICATION_CODE' | translate }}</p>
        <p class="help-text">{{ 'LOGIN_MODAL.ENTER_CODE_YOU_GOT' | translate }}</p>
        <app-button
          (clickEmitter)="reSendCode()"
          [variant]="'secondary'"
          [size]="'small'"
          [rounded]="true"
        >
          {{ 'LOGIN_MODAL.PHONE_RESEND_CODE' | translate }}
        </app-button>
        <div class="code-input-form">
          <app-split-input
            (codeFull)="getCodeFromInput($event)"
            [isSendingToServer]="isSendingToServer"
          >
          </app-split-input>
          <div class="invalid-feedback" *ngIf="showCodeInvalidMessage">
            {{ showCodeInvalidMessage }}
          </div>
        </div>
        <app-button
          *ngIf="!isSendingToServer"
          (clickEmitter)="submitCode()"
          [disabled]="isSendingToServer || !codeValue"
          [rounded]="true"
        >
          {{ 'LOGIN_MODAL.NEXT_BUTTON' | translate }}
        </app-button>
        <app-loader *ngIf="isSendingToServer"></app-loader>
      </div>
    </ng-container>

    <!-- Forgot password -->
    <ng-container *ngIf="state === AuthModalState.ForgotPassword">
      <!-- Back button -->
      <button class="btn-base back-button" type="button" (click)="goToLogin()">
        <i class="icon iconly-Arrow-Left icli"></i> {{ 'LOGIN_MODAL.BUTTON_BACK' | translate }}
      </button>
      <div class="wide-wrapper">
        <!-- Big icon -->
        <app-icon [name]="'Key'" [size]="'56px'" class="big-icon-circle"></app-icon>
        <!-- Title -->
        <p class="title text-center">{{ 'LOGIN_MODAL.NO_BIGGIE_WEVE_GOT_YOU' | translate }}</p>
        <p class="help-text help-text-password">
          {{ 'LOGIN_MODAL.GIVE_US_EMAIL_TO_HELP' | translate }}
        </p>
      </div>
      <div class="narrow-wrapper">
        <!-- Form -->
        <form
          class="form-input-wrapper"
          (ngSubmit)="sendForgotPassword()"
          [formGroup]="forgotPasswordForm"
        >
          <div class="form-group">
            <label class="sr-only" for="email">{{ 'LOGIN_MODAL.EMAIL_ADDRESS' | translate }}</label>
            <input
              class="form-control"
              id="email"
              name="email"
              placeholder="Email"
              type="email"
              formControlName="email"
              required=""
            />
            <div
              class="invalid-feedback"
              *ngIf="
                forgotPasswordForm.controls['email'].touched &&
                forgotPasswordForm.controls['email'].invalid
              "
            >
              {{ 'ERROR.INVALID_EMAIL_ADDRESS' | translate }}
            </div>
          </div>
          <app-button
            [disabled]="isSendingToServer"
            class="btn-submit"
            [type]="'submit'"
            [rounded]="true"
            [fullWidth]="true"
          >
            {{ 'LOGIN_MODAL.SEND_THE_LINK' | translate }}
          </app-button>
        </form>
      </div>
    </ng-container>

    <!-- Reset password -->
    <ng-container *ngIf="state === AuthModalState.ResetPassword">
      <!-- Back button -->
      <button class="btn-base back-button" type="button" (click)="state = AuthModalState.Login">
        <i class="icon iconly-Arrow-Left icli"></i> {{ 'LOGIN_MODAL.BUTTON_BACK' | translate }}
      </button>
      <div class="wide-wrapper">
        <!-- Big icon -->
        <!-- delete file -->
        <app-icon [name]="'Key'" [size]="'56px'" class="big-icon-circle"></app-icon>
        <!-- Title -->
        <p class="title text-center">{{ 'LOGIN_MODAL.CREATE_NEW_PASSWORD' | translate }}</p>
        <p class="help-text">{{ 'LOGIN_MODAL.LOGIN_WITH_NEW_PASSWORD' | translate }}</p>
      </div>
      <div class="narrow-wrapper">
        <!-- Form -->
        <form
          class="form-input-wrapper"
          (ngSubmit)="sendResetPassword()"
          [formGroup]="resetPasswordForm"
        >
          <div class="form-group">
            <label class="sr-only" for="password">{{ 'LOGIN_MODAL.PASSWORD' | translate }}</label>
            <input
              [type]="showPassword ? 'text' : 'password'"
              class="form-control form-control-password"
              formControlName="password"
              id="password"
              name="password"
              placeholder="Password"
              required
            />
            <button
              (click)="toggleShowPassword('password')"
              class="password-visibility"
              type="button"
            >
              <img
                [src]="
                  showPassword
                    ? 'assets/images/icons/eye-icon.svg'
                    : 'assets/images/icons/closed-eye-icon.svg'
                "
                alt="Hide password"
              />
            </button>
            <div
              class="invalid-feedback"
              *ngIf="
                resetPasswordForm.controls.password.invalid &&
                resetPasswordForm.controls.password.dirty
              "
            >
              {{ 'ERROR.SIGN_IN_INVALID_PASSWORD' | translate }}
            </div>
          </div>
          <div class="form-group">
            <label class="sr-only" for="confirm-password">{{
              'LOGIN_MODAL.CONFIRM_PASSWORD' | translate
            }}</label>
            <input
              [type]="showConfirmPassword ? 'text' : 'password'"
              class="form-control form-control-password"
              formControlName="confirmPassword"
              id="confirm-password"
              name="confirm-password"
              placeholder="Confirm Password"
              required
            />
            <button
              (click)="toggleShowPassword('confirmPassword')"
              class="password-visibility"
              type="button"
            >
              <img
                [src]="
                  showConfirmPassword
                    ? 'assets/images/icons/eye-icon.svg'
                    : 'assets/images/icons/closed-eye-icon.svg'
                "
                alt="Show password"
              />
            </button>
            <div
              class="invalid-feedback"
              *ngIf="
                resetPasswordForm.controls.confirmPassword.invalid &&
                resetPasswordForm.controls.confirmPassword.dirty
              "
            >
              {{ 'ERROR.PASSWORD_DONT_MATCH' | translate }}
            </div>
          </div>
          <button
            class="btn-base btn-rounded btn-large btn-primary-outline btn-submit mt-4"
            type="submit"
          >
            {{ 'LOGIN_MODAL.CONFIRM' | translate }}
          </button>
        </form>
      </div>
    </ng-container>

    <!-- Link Accounts -->
    <ng-container *ngIf="state === AuthModalState.LinkAccounts">
      <!-- Back button -->
      <button class="btn-base back-button" type="button" (click)="goToLogin()">
        <i class="icon iconly-Arrow-Left icli"></i> {{ 'LOGIN_MODAL.BUTTON_BACK' | translate }}
      </button>
      <div class="narrow-wrapper justify-content-center">
        <!-- Title -->
        <p class="title text-center">You've been here before</p>
        <p class="help-text">
          It seems that you've already used your email account to log in, please verify your account
        </p>
      </div>
      <div class="narrow-wrapper flex-grow-0">
        <button
          class="btn-login"
          *ngIf="originalAccounts.includes('google.com')"
          type="button"
          (click)="linkAccountsWithOriginalProvider('Google')"
          [disabled]="isSendingToServer"
        >
          <img alt="Google" class="icon google" src="assets/images/google-g.svg" />
          <div class="seperator"></div>
          {{ 'LOGIN_MODAL.LOG_IN_GOOGLE' | translate }}
        </button>
        <button
          class="btn-login"
          *ngIf="originalAccounts.includes('facebook.com')"
          type="button"
          (click)="linkAccountsWithOriginalProvider('Facebook')"
          [disabled]="isSendingToServer"
        >
          <img alt="Facebook" class="icon" src="assets/images/facebook-f.svg" />
          <div class="seperator"></div>
          {{ 'LOGIN_MODAL.LOG_IN_FACEBOOK' | translate }}
        </button>
        <form
          *ngIf="originalAccounts.includes('password')"
          (ngSubmit)="linkAccountsWithEmailAndPassword()"
          [formGroup]="linkAccountsForm"
        >
          <div class="form-input-wrapper">
            <div class="form-group">
              <label class="sr-only" for="email">{{
                'LOGIN_MODAL.EMAIL_ADDRESS' | translate
              }}</label>
              <input
                class="form-control"
                name="email"
                placeholder="Email"
                type="email"
                formControlName="email"
                required
                email
                (click)="removeErrorMessageSignInForm('email')"
              />
              <div
                class="invalid-feedback"
                *ngIf="
                  showEmailInvalidMessage ||
                  (signInForm.controls.email.invalid && signInForm.controls.email.touched)
                "
              >
                {{ 'ERROR.SIGN_IN_INVALID_EMAIL' | translate }}
              </div>
            </div>
            <div class="form-group">
              <label class="sr-only" for="password">{{ 'LOGIN_MODAL.PASSWORD' | translate }}</label>
              <input
                class="form-control"
                name="password"
                placeholder="Password"
                type="password"
                formControlName="password"
                required
                (click)="removeErrorMessageSignInForm('password')"
              />
              <div
                class="invalid-feedback"
                *ngIf="signInForm.controls.password.invalid && signInForm.controls.password.touched"
              >
                {{ 'ERROR.SIGN_IN_INVALID_PASSWORD' | translate }}
              </div>
              <a class="forgot-password-link" (click)="goToForgotPassword()">{{
                'LOGIN_MODAL.SIGN_IN_FORGOT_PASSWORD' | translate
              }}</a>
            </div>
            <button
              class="btn-base btn-rounded btn-large btn-primary-outline btn-submit"
              type="submit"
              [disabled]="isSendingToServer"
            >
              {{ 'LOGIN_MODAL.LOG_IN' | translate }}
            </button>
          </div>
        </form>
        <div class="text-center pt-3 pb-5">
          <p class="mb-2 h6">Or</p>
          <button type="button" class="btn btn-link text-primary text-uppercase font-weight-medium">
            Verify with SMS code
          </button>
        </div>
      </div>
    </ng-container>

    <!-- Full Name -->
    <ng-container
      *ngIf="state === AuthModalState.AskForDetails && showFields && fillMissingDataForm"
    >
      <button class="btn-base back-button" type="button" (click)="modal.dismiss()">
        <i class="icon iconly-Arrow-Left icli"></i> {{ 'LOGIN_MODAL.BUTTON_BACK' | translate }}
      </button>
      <div class="wide-wrapper full-name-wrapper">
        <app-icon
          [name]="showFields.icon ?? 'Profile'"
          [size]="'56px'"
          class="big-icon-circle"
        ></app-icon>
        <!-- Title -->
        <p class="title text-center">
          {{ showFields.title ?? 'LOGIN_MODAL.ALMOST_THERE' | translate }}
        </p>
        <p class="help-text" *ngIf="!!showFields?.subtitle">
          {{ showFields.subtitle ?? '' | translate }}
        </p>
      </div>
      <div class="wide-wrapper">
        <!-- Form -->
        <form
          class="form-input-wrapper phone-input-wrapper"
          (ngSubmit)="submitMissingDataForm()"
          [formGroup]="fillMissingDataForm"
        >
          <ng-container *ngIf="showFields.displayName">
            <div class="form-group">
              <div class="help-text-v2 mt-2">
                {{ 'LOGIN_MODAL.FULL_NAME_SUB_TITLE' | translate }}
              </div>
              <label class="sr-only" for="fullName">{{
                'LOGIN_MODAL.FULL_NAME' | translate
              }}</label>
              <input
                class="form-control"
                id="fullName"
                name="fullName"
                placeholder="{{ 'LOGIN_MODAL.FULL_NAME' | translate }}"
                type="text"
                formControlName="fullName"
                required
                (click)="removeErrorMessageFillMissingDataForm('fullName')"
              />
              <div
                class="invalid-feedback"
                [style.visibility]="
                  fillMissingDataForm.controls['fullName'].touched &&
                  fillMissingDataForm.controls['fullName'].invalid
                    ? 'visible'
                    : 'hidden'
                "
              >
                {{ 'ERROR.INVALID_NAME' | translate }}
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="showFields.phone">
            <div class="help-text-v2 my-2">
              {{ 'LOGIN_MODAL.WHAT_IS_YOUR_PHONE_NUMBER' | translate }}
            </div>
            <div class="form-row form-row-phone">
              <div class="form-group col-6 col-xl-5">
                <ng-select
                  class="custom-style phone-prefix"
                  formControlName="phonePrefix"
                  id="inputPhonePrefix"
                  [(ngModel)]="selectedCountry"
                  [searchFn]="customSearchFn"
                  [items]="countryCodes$ | async"
                  bindLabel="dialCode"
                >
                  <ng-template ng-option-tmp ng-label-tmp let-item="item">
                    <i
                      class="flag flag-24"
                      [ngClass]="
                        item.isoCode ? 'flag-' + item.isoCode.toLocaleLowerCase() : 'flag-unknown'
                      "
                    ></i>
                    {{ item.dialCode }}
                    {{ item.name }}
                  </ng-template>
                </ng-select>
              </div>
              <div class="form-group col-6 col-xl-7 mt-auto">
                <div class="form-input-wrapper">
                  <label class="sr-only" for="phone-number"
                    >{{ 'LOGIN_MODAL.PHONE_NUMBER' | translate }}
                  </label>
                  <input
                    class="form-control"
                    formControlName="phoneNumber"
                    id="phone-number"
                    required
                    type="tel"
                  />
                  <div class="invalid-feedback" *ngIf="showPhoneInvalidMessage">
                    showPhoneInvalidMessage
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="showFields.email">
            <div class="form-group">
              <div class="help-text-v2 mt-2">
                {{ 'LOGIN_MODAL.WHAT_IS_YOUR_EMAIL_ADDRESS' | translate }}
              </div>
              <div class="sub-help-text-v2">
                {{ 'LOGIN_MODAL.WE_ARE_NOT_INTO_SPAM' | translate }}
              </div>

              <label class="sr-only" for="email">{{
                'LOGIN_MODAL.EMAIL_ADDRESS' | translate
              }}</label>
              <input
                class="form-control"
                id="email"
                name="email"
                placeholder="{{ 'LOGIN_MODAL.EMAIL' | translate }}"
                type="email"
                formControlName="email"
                required
                email
                (click)="removeErrorMessageFillMissingDataForm('email')"
              />
              <div
                class="invalid-feedback"
                [style.visibility]="
                  showEmailInvalidMessage ||
                  (fillMissingDataForm.controls.email.invalid &&
                    fillMissingDataForm.controls.email.touched)
                    ? 'visible'
                    : 'hidden'
                "
              >
                {{ 'ERROR.SIGN_IN_INVALID_EMAIL' | translate }}
              </div>
            </div>
            <div class="agreed-policy-container mb-4">
              <div class="agreed-checkbox-placeholder">
                <app-check-box
                  [checked]="agreedPolicy"
                  (change)="toggleAgreedPolicy()"
                ></app-check-box>
              </div>
              <div class="legal-text ml-3">
                {{ 'MOBILE_CHECKOUT.I_AGREE_TO_TERRIFIC' | translate }}
                <a [href]="'MOBILE_CHECKOUT.USER_AGREEMENT_LINK' | translate" target="_blank">{{
                  'MOBILE_CHECKOUT.USER_AGREEMENT' | translate
                }}</a>
                {{ 'MOBILE_CHECKOUT.AND' | translate }}
                <a [href]="'MOBILE_CHECKOUT.PRIVACY_LINK' | translate" target="_blank">{{
                  'MOBILE_CHECKOUT.PRIVACY' | translate
                }}</a
                >.
              </div>
            </div></ng-container
          >

          <app-button
            (click)="isLoginPage ? onRegister() : null"
            [disabled]="isSendingToServer"
            [type]="'submit'"
            [rounded]="true"
            [disabled]="!fillMissingDataForm.valid || (!agreedPolicy && showFields.email)"
          >
            {{ 'LOGIN_MODAL.FINISH' | translate }}
          </app-button>
        </form>
      </div>
    </ng-container>
    @if(!resetRecaptcha) {
    <div
      id="recaptcha-container"
      style="display: flex; justify-content: center; padding: 10px"
    ></div>
    }
  </div>
</div>
