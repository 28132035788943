import {inject, Injectable} from '@angular/core';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {getBlob, getMetadata, listAll, ref, Storage} from '@angular/fire/storage';
import {saveAs} from 'file-saver';
import type * as JSZip from 'jszip';
import {ActiveToast} from 'ngx-toastr';
import {map, Observable, Subject, throwError} from 'rxjs';
import {FileHelpers} from '../helpers/file-helpers';
import {LanguageService} from '../language.service';
import {LogService} from '../logger/logger.service';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private storage = inject(AngularFireStorage);
  private modularStorage = inject(Storage);
  private languageService = inject(LanguageService);

  private logService = inject(LogService);

  public getToken(url: string) {
    return this.storage.refFromURL(url).getDownloadURL();
  }

  public async downloadFolderAsZip(directoryPath: string, toast: ActiveToast<{message: string}>) {
    try {
      // @ts-expect-error ts type is wrong here, the export uses a default export
      const {default: JSZip} = await import('jszip');
      const zip = new JSZip();
      const name = directoryPath.split('/').pop()!;
      await this.addFilesFromDirectoryToZip(zip, directoryPath, directoryPath, toast, [], []);
      const blob = await zip.generateAsync({type: 'blob'});
      saveAs(blob, name);
    } catch (error) {
      this.logService.error(`StorageService ~ downloadFolderAsZip ~ error`, error);
    }
  }

  public listAllFilesInDirectory(directoryPath: string, extensions?: string[]) {
    const storage = this.storage;
    const allFiles = storage.ref(directoryPath).listAll();
    return allFiles.pipe(
      map((files) =>
        !extensions?.length
          ? files.items
          : files.items.filter((file) =>
              extensions.some((extension) => file.name.endsWith(extension))
            )
      )
    );
  }

  private async addFilesFromDirectoryToZip(
    zip: JSZip,
    directoryPath: string,
    rootPath: string,
    toast: ActiveToast<{message: string}>,
    totalFiles: unknown[],
    finishedPool: unknown[]
  ) {
    const storage = this.modularStorage;

    const directoryContentsRef = ref(storage, directoryPath);
    const directoryContents = await listAll(directoryContentsRef);

    const promisePool: Promise<void>[] = [];
    for (const folder of directoryContents.prefixes) {
      promisePool.push(
        (async () =>
          await this.addFilesFromDirectoryToZip(
            zip,
            folder.fullPath,
            rootPath,
            toast,
            totalFiles,
            finishedPool
          ))()
      );
    }
    if (!directoryContents.items.length) {
      await Promise.all(promisePool);
      return;
    }

    for (const file of directoryContents.items) {
      if (!file.name.endsWith('.mp4')) continue;
      totalFiles.push({});
      promisePool.push(
        (async () => {
          const fileRef = ref(storage, file.fullPath);

          printDownloadProgress(this.languageService, toast, totalFiles, finishedPool);

          const [fileBlob, metaData] = await Promise.all([getBlob(fileRef), getMetadata(fileRef)]);

          finishedPool.push({});

          const name = file.fullPath.split('/').pop()!;
          printDownloadProgress(this.languageService, toast, totalFiles, finishedPool);
          zip.file(name, fileBlob, {
            date: new Date(metaData.timeCreated),
          });
        })()
      );
    }
    printDownloadProgress(this.languageService, toast, totalFiles, finishedPool);
    await Promise.all(promisePool);
    printDownloadProgress(this.languageService, toast, totalFiles, finishedPool);
  }

  public uploadFileToStorage(
    filePath: string,
    file: File,
    allowedExtensions: string,
    maxFileSizeInMb: number
  ): Observable<string> {
    let response: Observable<string>;

    const message = FileHelpers.validateFile(file, allowedExtensions, maxFileSizeInMb);
    if (message !== 'OK') {
      response = throwError({
        message,
      });
    } else {
      const subject = new Subject<string>();
      response = subject.asObservable();

      this.storage
        .upload(filePath, file)
        .then((snapshot) => {
          snapshot.ref
            .getDownloadURL()
            .then((downloadUrl: string) => {
              subject.next(downloadUrl);
            })
            .catch((err) => {
              subject.error(err);
            })
            .finally(() => {
              subject.complete();
            });
        })
        .catch((err) => {
          subject.error(err);
          subject.complete();
        });
    }

    return response;
  }

  /**
   * Delete an image when cartProduct remove from cart
   *
   * @param path
   */
  public deleteImage(path: string) {
    const fileRef = this.storage.ref(path);
    fileRef.delete().subscribe();
  }

  public deleteFiles(paths: string[]) {
    paths.forEach((path) => {
      const fileRef = this.storage.ref(path);
      fileRef.delete().subscribe({
        error: (e) => {
          this.logService.error(`Error deleting file at path: ${path}`, e);
        },
      });
    });
  }
}

const printDownloadProgress = (
  languageService: LanguageService,
  toast: ActiveToast<{message: string}>,
  totalFiles: unknown[],
  finishedPool: unknown[]
) => {
  const downloaded = (finishedPool.length + 1).toString();
  const total = (totalFiles.length + 1).toString();
  const prefix = languageService.translateSync('STORE_SESSIONS.DOWNLOADING_FILES_MESSAGE_PREFIX');
  const zipping = languageService.translateSync('STORE_SESSIONS.ZIPPING');
  toast.toastRef.componentInstance.message =
    total !== downloaded
      ? `${prefix} ${downloaded} / ${total}`
      : `${prefix} ${downloaded} / ${total} ${zipping}`;
};
