import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, catchError, Observable, shareReplay, throwError} from 'rxjs';
import {SessionDataDTO} from '../../../../shared/dto-models/session-data';
import {PartiallyRequired} from '../../../../shared/utilities/type-helpers';
import {ConnectedUserModel} from '../interfaces/users-models';
import {AnalyticsService} from './analytics.service';

import {LogService} from '../logger/logger.service';
import type {BaseLiveSessionComponent} from '../session/shared/base-live-session.component';

// DO NOT STORE LOGIC NOR VALUES IMPORTS IN THIS CLASS FILE.
// ITS A TOP LEVEL CLASS.
@Injectable({
  providedIn: 'root',
})
export class StateHolderService {
  analytics = inject(AnalyticsService);
  private logService = inject(LogService);

  public readonly iAmBroadcasting$ = new BehaviorSubjectWithToggle(false);
  public readonly hasUserInteracted$: BehaviorSubject<true> = new BehaviorSubject(false) as any;

  public readonly isAgoraPlayingVideo$ = new BehaviorSubjectWithToggle(false);

  public readonly isAgoraRecording$ = new BehaviorSubjectWithToggle(false);

  public readonly isMuted$ = new BehaviorSubjectWithToggle(true);

  public readonly connectedUser$ = new BehaviorSubject<Readonly<ConnectedUserModel | null>>(null);

  public readonly themeActive$ = new BehaviorSubjectWithToggle(false);

  public readonly session$ = new BehaviorSubject<
    Readonly<PartiallyRequired<SessionDataDTO, 'id'> | null>
  >(null);

  public globalStoreUrl$ = new BehaviorSubject<string | null>(null);

  public readonly currentSessionComponent$ = new BehaviorSubject<BaseLiveSessionComponent | null>(
    null
  );

  public readonly isVisible$ = new Observable<boolean>((observer) => {
    const visibilityChanged = () => {
      observer.next(!document.hidden);
    };
    visibilityChanged();
    document.addEventListener('visibilitychange', visibilityChanged);
    return () => {
      document.removeEventListener('visibilitychange', visibilityChanged);
    };
  }).pipe(shareReplay({refCount: true, bufferSize: 1}));

  public readonly isInPictureInPicture$ = new BehaviorSubjectWithToggle(false);

  constructor() {
    this.isMuted$.subscribe((state) => {
      if (this.isMuted$) {
        this.analytics.logEvent('select_content', {
          path: window.location.pathname,
          content_type: 'Mute/Un-mutes_the_video_sound',
          isMuted: state,
        });
      }
    }),
      catchError((e) => {
        this.logService.error('state holder service ~ constructor', e);
        return throwError(() => {
          throw e;
        });
      });
  }
}

class BehaviorSubjectWithToggle extends BehaviorSubject<boolean> {
  toggle() {
    this.next(!this.value);
  }
}
